:root {
  --main-color: #162552;
  --accent-color: #f64b38;
  --accent-hover: #f3341f;
  --muted-color: #8b93b0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-color);
  font-size: 16px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--main-color);
}

.center-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 7em 0 5em;
  box-sizing: border-box;
}

.upload-tool {
  text-align: center;
  padding: 3em;
  background: white;
  width: 600px;
  max-width: 80%;
  margin: auto;
  box-sizing: border-box;
  border-radius: 0.4em;
  box-shadow: 0 5px 5px #00000059;
  border-top: 10px solid var(--accent-color);
  border-radius: 20px;
}

@media screen and (min-width: 900px) {
  body {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .upload-tool {
    padding: 2em;
    max-width: 90%;
  }
}

h1 {
  font-weight: 700;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0.2em;
}
a {
  color: var(--accent-color);
  transition: color 0.2s, background 0.2s;
}
a:hover {
  color: var(--accent-hover);
}
.text-muted {
  color: var(--muted-color);
}
.text-mono {
  font-family: monospace;
}
.lead {
  font-size: 1.15em;
  margin-top: 0;
  margin-bottom: 1rem;
}
.sub-title {
  color: var(--muted-color);
}
.sub-title .hl {
  color: darksalmon;
}
strong {
  font-weight: 600;
}

/* Expired */

.expired-message h3 {
  margin-bottom: 0.5em;
}
.expired-message p {
  margin-top: 0;
}
.expired-message .btn-wrap {
  margin-top: 2em;
}

/* Buttons */

.btn {
  color: white;
  font-weight: 700;
  text-decoration: none;
  padding: 0.65em 2em;
  display: inline-block;
  border-radius: 48px;
  font-size: 18px;
  width: 12em;
  max-width: 100%;
}
.btn:hover {
  color: white;
  font-weight: 700;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .btn {
    display: block;
    width: auto;
  }
}
.btn-accent {
  background: var(--accent-color);
}
.btn-accent:hover,
.btn-accent:active,
.btn-accent:focus {
  background: var(--accent-hover);
}
.btn-main {
  background: var(--main-color);
}
.btn-main:hover,
.btn-main:active,
.btn-main:focus {
  background: #0e1837;
}
.btn svg {
  float: right;
  margin-top: 0.2em;
  margin-right: -0.6em;
}

/* Dropzone */
.dropzone {
  border: 2px dashed #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3.5em 3.5em 2em;
  margin-bottom: 0.3em;
  margin-top: 2.5em;
}
.dropzone:hover {
  border-color: rgb(0 63 95 / 25%);
}
.dropzone .icon-wrap {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropzone .icon-wrap svg {
  font-size: 5em;
  color: #7797a7;
  color: var(--main-color);
  opacity: 0.3;
  transition: font-size 0.4s, opacity 0.4s;
}
.dropzone:hover svg {
  font-size: 5.75em;
  opacity: 0.6;
}
.dropzone .drag-active svg {
  font-size: 6em;
  opacity: 0.7;
}
.dropzone .dropzone-label {
  margin-bottom: 0;
  line-height: 1.6;
  margin-top: 0.5em;
  min-height: 50px;
  color: var(--muted-color);
}
.dropzone .dropzone-label span {
  display: block;
}
.dropzone .dropzone-label span:not(:first-child) {
  font-size: 0.9em;
}

/* Preview */
.preview-wrap {
  margin: 0 -4px;
  display: flex;
  flex-wrap: wrap;
}
.preview-block-outer {
  padding: 4px;
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
@media screen and (max-width: 500px) {
  .preview-block-outer {
    width: 50px;
    height: 50px;
  }
}
.preview-block {
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-block img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
}
.overlay-icon {
  position: absolute;
  inset: 0;
}
.overlay-icon img {
  width: 75%;
}
.overlay-icon-block svg {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 1.5em;
}
.preview-icon {
  color: #ddd;
  width: 100%;
  height: 100%;
  padding: 0.6em;
}

/* Alert */
.message {
  padding: 1.3em;
  position: relative;
}
.message.margin {
  margin-top: 0.5em;
}
.message.clickable {
  cursor: pointer;
}
.message h4 {
  font-weight: 700;
  margin-bottom: 0.2em;
  margin-top: 0;
}
.message h4:last-child {
  margin-bottom: 0;
}
.message p {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.message.success {
  background: #32cd322e;
}
.message.success h4 {
  color: #4a914d;
}
.message.error {
  background: #fa80728c;
}
.message.error h4 {
  color: #c6584c;
}
.message .text-muted {
  color: rgb(0 0 0 / 35%);
}
.message h4 .text-muted {
  color: inherit;
  opacity: 0.5;
}
.message .main-icon {
  font-size: 4em;
  margin-bottom: 0.1em;
}
.message-img {
  display: block;
  height: auto;
  width: 120px;
  max-width: 100%;
  margin: 0 auto 1em;
  color: var(--muted-color);
  opacity: 0.5;
}
.message .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  outline: none;
  border: none;
  padding: 1em;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s;
}
.message .close-btn:hover {
  opacity: 1;
}
.inline-icon {
  font-size: 1.2em;
  vertical-align: -0.2em;
  margin-right: 0.3em;
}

/* Nav  */
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 2em;
}
@media (max-width: 500px) {
  .main-nav {
    padding: 1.7em;
    text-align: center;
  }
}
.nav-logo {
  max-height: 100px;
  max-width: 100%;
  height: auto;
  width: 300px;
  margin: auto;
}

/* Footer */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 1em;
  font-size: 0.85em;
  color: rgb(255 255 255 / 50%);
  z-index: -1;
  text-align: center;
}

/* Effects */
@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: spin infinite 2s linear;
  }
  .scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
